<template>
  <div>
    <div class="d-flex justify-space-between mb-6 text-body-lg font-weight-medium align-center">Notifications
      <UiBtn color="primary" @click="generalNotificationsSettingDrawer = true">General Settings</UiBtn>
    </div>
    <v-data-table
      :headers="headers"
      :items="getSpacesNotifications"
      :items-per-page="-1"
      :mobile-breakpoint="280"
      hide-default-footer
      class="rounded-0"
      :loading="loading"
    >
      <template v-slot:[`header.title`]="{ header }">
        <span class="header-title">{{ header.text }}</span>
      </template>
      <template v-slot:[`header.email_notifications`]="{ header }">
        <UiCheckbox
          v-model="selectedGroups.email_notifications"
          color="accent-100"
          class="mt-0 pt-0"
          hide-details
          :indeterminate="selectedGroupsIndeterminate.email_notifications"
          :disabled="loading || !getSpacesNotifications?.length"
          @change="onChangeGroup('email_notifications', $event)"
        >
          <template #label>
            <span class="font-weight-regular">{{ header.text }}</span>
          </template>
        </UiCheckbox>
      </template>
      <template v-slot:[`header.app_notifications`]="{ header }">
        <UiCheckbox
          v-model="selectedGroups.app_notifications"
          color="accent-100"
          class="mt-0 pt-0"
          hide-details
          :indeterminate="selectedGroupsIndeterminate.app_notifications"
          :disabled="loading || !getSpacesNotifications?.length"
          @change="onChangeGroup('app_notifications', $event)"
        >
          <template #label>
            <span class="font-weight-regular">{{ header.text }}</span>
          </template>
        </UiCheckbox>
      </template>
      <template v-slot:[`header.webpush_notifications`]="{header  }">
        <UiCheckbox
          v-model="selectedGroups.webpush_notifications"
          color="accent-100"
          class="mt-0 pt-0"
          hide-details
          :indeterminate="selectedGroupsIndeterminate.webpush_notifications"
          :disabled="loading || !getSpacesNotifications?.length"
          @change="onChangeGroup('webpush_notifications', $event)"
        >
          <template #label>
            <span class="font-weight-regular">{{ header.text }}</span>
          </template>
        </UiCheckbox>
      </template>
      <template #item="{item}">
        <tr class="spacer pointer-events-none">
          <td style="height: 10px;"/>
        </tr>
        <tr class="relative">
          <td class="text-body font-weight-semi-bold gray-100--text relative">
            <div class="left-title-border absolute" :style="{background: item.color}"/>
            {{ item.title }}
          </td>
          <td>
            <div class="d-flex align-center">
              <UiCheckbox
                v-model="item.notification_settings.email_notifications"
                label="Email"
                color="accent-100"
                class="mr-8"
                :disabled="item.loading"
                @change="saveNotifications([item])"/>
            </div>
          </td>
          <td>
            <UiCheckbox
              v-model="item.notification_settings.app_notifications"
              label="In App"
              color="accent-100"
              class="mr-8"
              :disabled="item.loading"
              @change="saveNotifications([item])"/>
          </td>
          <td class="d-flex align-center">
            <UiCheckbox
              v-model="item.notification_settings.webpush_notifications"
              label="Push"
              color="accent-100"
              class="mr-1"
              :disabled="item.loading"
              @change="saveNotifications([item])"/>
              <v-tooltip v-if="!getPushNotification || !isSupportPush" color="accent" top z-index="999">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" @click="subscribePush" class="d-flex cursor-pointer ml-1">
                    <IconInfoSquare width="14" class="accent--text"/>
                  </div>
                </template>
                <span v-if="!isSupportPush">Your browser not support push notification</span>
                <span v-else>Please enable browser notifications</span>
              </v-tooltip>
          </td>
        </tr>

      </template>
    </v-data-table>
    <GeneralNotificationsSetting v-model="generalNotificationsSettingDrawer"/>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {checkPushInBrowser, pushSubscribe} from "@/utils/pushNotificationUtils";
import UiCheckbox from "@/components/UI/UiCheckbox";
import GeneralNotificationsSetting from "@/components/notifications/GeneralNotificationsSetting.vue";
import UiBtn from "@/components/UI/UiBtn.vue";

export default {
  name: 'UserNotification',
  components: {
    UiBtn,
    GeneralNotificationsSetting,
    IconInfoSquare: () => import('@/components/icons/IconInfoSquare'),
    UiCheckbox,
  },
  data() {
    return {
      loading: false,
      generalNotificationsSettingDrawer: false,
      headers: [
        {text: "Space name", value: "title", sortable: false},
        {text: "Email", value: "email_notifications", sortable: false, width: '140px'},
        {text: "In-App", value: "app_notifications", sortable: false, width: '140px'},
        {text: "Push", value: "webpush_notifications", sortable: false, width: '140px'},
      ],
      selectedGroups: {
        email_notifications: false,
        app_notifications: false,
        webpush_notifications: false,
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsNotifications',
      'getSpacesNotifications',
      'getPushNotification',
    ]),
    isSupportPush() {
      return checkPushInBrowser();
    },
    selectedGroupsIndeterminate() {
      return {
        email_notifications: this.isIndeterminate('email_notifications', this.getSpacesNotifications),
        app_notifications: this.isIndeterminate('app_notifications', this.getSpacesNotifications),
        webpush_notifications: this.isIndeterminate('webpush_notifications', this.getSpacesNotifications),
      }
    }
  },
  async created() {
    await this.loadNotifications();
    Object.keys(this.selectedGroups).forEach(key => {
      this.setGroupValue(key);
    });
  },
  methods: {
    async subscribePush() {
      try {
        await pushSubscribe()
        if (Notification.permission === 'granted') {
          await this.$store.dispatch('setPushNotification', true);
        }
      } catch (e) {
        console.warn('Notifications are not supported by this browser');
      }

    },
    async saveNotifications(spaces, data) {
      spaces.forEach(el => {
        el.loading = true
      })
      this.loading = true
      try {
        let payload = {}
        if(!data) {
          payload = spaces[0].notification_settings
        } else {
          payload = data
        }
        const spacesIds = spaces.map(el => el.id)
        const res = await this.$api.notification.saveSpacesNotifications({spaces: spacesIds, ...payload});
        await this.updateNotificationsInStore(res.data)
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false
        spaces.forEach(el => {
          el.loading = false
        })
      }
    },
    async loadNotifications() {
      try {
        this.loading = true;
        const res = await this.$api.notification.getSpacesNotifications();
        await this.$store.dispatch('setSpacesNotifications', res.data);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async onChangeGroup(type, value) {
      const payload = {}
      payload[type] = value
      await this.saveNotifications(this.getSpacesNotifications, payload)
    },
    isIndeterminate(type, spacesNotifications) {
      const selectedLength = spacesNotifications?.filter(item => item?.notification_settings?.[type]).length;
      return !!(selectedLength && selectedLength < spacesNotifications?.length && this.selectedGroups[type]);
    },
    setGroupValue(type) {
      const selectedLength = this.getSpacesNotifications?.filter(item => item?.notification_settings?.[type]).length;
      if(selectedLength && selectedLength <= this.getSpacesNotifications?.length) {
        this.selectedGroups[type] = true;
      } else if(selectedLength === 0) {
        this.selectedGroups[type] = false;
      }
    },
    async updateNotificationsInStore(data) {
      const payload = this.getSpacesNotifications.map(space => {
        const updatedItem = data.find(item => item.space_id === space.id)
        if(updatedItem) {
          return {
            ...space,
            notification_settings: {
              app_notifications: updatedItem.app_notifications,
              email_notifications: updatedItem.email_notifications,
              webpush_notifications: updatedItem.webpush_notifications
            }
          }
        }
        return space
      })
      await this.$store.dispatch('setSpacesNotifications', payload);
      Object.keys(this.selectedGroups).forEach(key => {
        this.setGroupValue(key);
      });
    }
  },
}
</script>

<style scoped lang="scss">
.header-title {
  font-size: 11px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 400;
  color: var(--v-gray-60-base);
}
.left-title-border {
  top: 0;
  left: 0;
  bottom: 0;
  width: 6px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
::v-deep .v-data-table {

  .v-data-table-header tr th {
    border-bottom: 1px solid var(--v-gray-30-base) !important;
    border-top: none !important;

    &:first-child {
      padding-left: 26px;
    }
  }

  tbody tr {
    &:not(.spacer) {
      border-radius: 6px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
    }

    &.spacer td {
      border: none !important;

      &::before {
        display: none;
      }
    }


    &:not(.spacer) td {
      height: 64px !important;
      border: 0px !important;

      &:first-child {
        position: relative;
        padding-left: 26px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}

::v-deep .ui-checkbox .v-label {
  font-size: 14px;
  color: var(--v-gray-60-base);
}
</style>
